import React, { useState } from "react";
import { sendFeedback } from "../api/feedbackApi";
import { useUserContext } from "../authentication/userContext";
import Button from "../uiKit/button";
import { toast } from "react-toastify";

interface QuestionFeedbackProps {
  questionId: string;
}

const QuestionFeedback: React.FC<QuestionFeedbackProps> = ({ questionId }) => {
  const [feedback, setFeedback] = useState("");
  const { user } = useUserContext();

  const handleSubmit = async () => {
    try {
      await sendFeedback({
        content: feedback,
        userEmail: user?.email,
        questionId,
      });
      toast("We got it! Thanks for the feedback🙏", {
        type: "success",
      });
      setFeedback("");
    } catch (error) {
      toast(
        "Something went wrong and we didn't get your'e feedback:( Please try again or contact us at admin@data-noobs.com",
        {
          type: "error",
        },
      );
    }
  };

  return (
    <div className="flex flex-col items-center p-3 border rounded-lg shadow-md w-full max-w-md 0 h-fit mb-4">
      <textarea
        className="w-full p-2 mb-4 border rounded resize-none h-24 focus:outline-none focus:ring-2 focus:ring-dnBlue"
        placeholder="Let us know what you think of this question"
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <Button
        onClick={handleSubmit}
        disabled={!feedback.trim()}
        className={`px-4 py-2 text-white rounded ${
          feedback.trim() ? "" : "cursor-not-allowed"
        }`}
      >
        Submit Feedback
      </Button>
    </div>
  );
};

export default QuestionFeedback;
