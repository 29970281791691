import LoginButton from "./LoginButton";

const Header: React.FC<{ showLoginButton?: boolean }> = ({
  showLoginButton = true,
}) => {
  return (
    <div className="flex flex-row justify-between items-center bg-dnBlue w-full h-auto px-4">
      <div className="flex items-center">
        {showLoginButton && <LoginButton />}
      </div>
      <div className="h-full object-cover w-[200px]">
        <img
          src="/logo_white.png"
          alt="Data Noobs"
          className="w-full h-auto object-contain"
        />
      </div>
    </div>
  );
};

export default Header;
