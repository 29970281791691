import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useState } from "react";
import { createPayment, validatePayment } from "../../api/paymentApi";
import { useUserContext } from "../userContext";
import { SyncLoader } from "react-spinners";
import Button from "../../uiKit/button";
import { useNavigate, useParams } from "react-router-dom";
import { PAYMENT_MONTHS } from "../../App";
import { PayPalButtonsWrapper } from "./payPalButton";
import environment from "../../config/environment";
import { useMixpanel } from "react-mixpanel-browser";

export const paymentMonthsPrices: Record<string, number> = {
  "1": 30.0,
  "3": 60.0,
  "6": 100.0,
};

const PaymentPage = () => {
  const params = useParams();
  const paymentMonths = params[PAYMENT_MONTHS] as string;

  const [error, setError] = useState<string | null>(null);
  const [paidFor, setPaidFor] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const { user } = useUserContext();
  const mixpanel = useMixpanel();

  const createOrder = async (
    data: any,
    actions: { order: { create: (arg: any) => any } },
  ) => {
    try {
      mixpanel?.track("payment_create_order", {
        email: user?.email,
        name: user?.fullName,
        months: paymentMonths,
      });

      if (!user) {
        mixpanel?.track("payment_error", {
          months: Number(paymentMonths),
          error: "User not found while creating order",
        });
        setError("User not found. Log in first");
        return Promise.resolve("error");
      }

      setIsLoading(true);

      const order = await actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: paymentMonthsPrices[paymentMonths],
            },
          },
        ],
      });

      await createPayment({ email: user.email, orderId: order });
      setIsLoading(false);
      return order;
    } catch (err) {
      mixpanel?.track("payment_error", {
        email: user?.email,
        name: user?.fullName,
        months: Number(paymentMonths),
        error: err,
      });
      setError(
        "Payment creation failed. Please try again or contact support at admin@data-noobs.com",
      );
    }
  };

  const handleApprove = async (orderId: string) => {
    try {
      mixpanel?.track("payment_approve_order", {
        email: user?.email,
        name: user?.fullName,
        months: Number(paymentMonths),
        orderId,
      });

      if (!user) {
        mixpanel?.track("payment_error", {
          months: Number(paymentMonths),
          orderId,
          error: "User not found while handling approve",
        });
        setError("User not found. Log in first");
        return;
      }

      const response = await validatePayment({
        orderId,
        email: user?.email,
        subscriptionMonths: Number(paymentMonths),
      });

      if (response.success) {
        mixpanel?.track("payment_success", {
          email: user?.email,
          name: user?.fullName,
          months: Number(paymentMonths),
          orderId,
        });
        setPaidFor(true);
      } else {
        mixpanel?.track("payment_error", {
          email: user?.email,
          name: user?.fullName,
          months: Number(paymentMonths),
          orderId,
          error: response.data,
        });
        setError(
          "Failed to create PayPal payment. Please try again or contact support at admin@data-noobs.com",
        );
      }
    } catch (error) {
      mixpanel?.track("payment_error", {
        email: user?.email,
        name: user?.fullName,
        months: Number(paymentMonths),
        orderId,
        error,
      });
      setError(
        "Payment creation failed. Please try again or contact support at admin@data-noobs.com",
      );
    }
  };

  if (user?.isPremium) {
    return (
      <div className="flex flex-col items-center pt-16 min-h-screen p-2 bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="font-bold text-3xl text-gray-800 p-2 rounded-lg mb-8">
          You're already a premium member 🎉
        </div>
        <Button className="" onClick={() => navigate("/")}>
          Back to Questions
        </Button>
      </div>
    );
  }

  if (!paymentMonthsPrices[paymentMonths]) {
    return (
      <div className="flex flex-col items-center pt-16 min-h-screen p-2 bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="font-bold text-3xl text-gray-800 p-2 rounded-lg mb-8">
          Something went wrong, please select a premium option
        </div>
        <Button className="" onClick={() => navigate("/premium")}>
          Back premium options
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center pt-16 min-h-screen p-4 bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="font-bold text-5xl text-gray-800 p-2 rounded-lg mb-2">
        {paidFor ? "Checkout Completed" : "Confirm Your Purchase"}
      </div>
      {paidFor ? null : (
        <div className="mb-8 font-semibold text-lg text-gray-600 rounded-lg md:px-16">
          <li>{`Get ${paymentMonths} ${paymentMonths === "1" ? "month" : "months"} access to the SQL practice platform`}</li>
          <li>{`Payment is for ${paymentMonthsPrices[paymentMonths]} USD`}</li>
          <li>
            <label htmlFor="terms">
              By paying you adhere to the{" "}
              <a
                href="https://data-noobs.com/%d7%aa%d7%a7%d7%a0%d7%95%d7%9f-%d7%a9%d7%99%d7%9e%d7%95%d7%a9-%d7%91%d7%90%d7%aa%d7%a8/"
                target="_blank"
                className="text-dnPurple underline"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://data-noobs.com/%d7%9e%d7%93%d7%99%d7%a0%d7%99%d7%95%d7%aa-%d7%a4%d7%a8%d7%98%d7%99%d7%95%d7%aa/"
                target="_blank"
                className="text-dnPurple underline"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </li>
        </div>
      )}
      <PayPalScriptProvider
        options={{
          clientId: environment.paypalClientId,
        }}
      >
        {paidFor ? (
          <div className="flex flex-col items-center mb-4 p-6 bg-white shadow-lg rounded-lg">
            <span className="mb-4 text-2xl text-green-600 font-bold">
              Payment Successful! 🎉
            </span>
            <span className="text-lg text-gray-600 mb-6 font-bold">
              You've unlocked access to all questions. Now it's time to start
              practicing and sharpen your SQL skills!
            </span>
            <Button className="" onClick={() => navigate("/")}>
              Explore all questions
            </Button>
          </div>
        ) : (
          <div className="w-full max-w-md bg-white p-6 shadow-lg rounded-lg">
            {error && (
              <div className="text-red-500 text-lg font-semibold mb-4 bg-red-100 p-3 rounded-lg">
                {error}
              </div>
            )}
            {!error && (
              <div className="flex flex-col py-4">
                <PayPalButtonsWrapper
                  createOrder={createOrder}
                  handleApprove={handleApprove}
                  setError={(error) => {
                    mixpanel?.track("payment_error", {
                      email: user?.email,
                      name: user?.fullName,
                      months: Number(paymentMonths),
                      error,
                    });
                    setError(error);
                  }}
                  setIsLoading={setIsLoading}
                />
              </div>
            )}
            {isLoading && (
              <div className="flex justify-center mt-6">
                <SyncLoader
                  color={"#6456FF"}
                  loading={isLoading}
                  cssOverride={{
                    display: "block",
                    margin: "0 auto",
                  }}
                  size={24}
                />
              </div>
            )}
          </div>
        )}
      </PayPalScriptProvider>
    </div>
  );
};

export default PaymentPage;
