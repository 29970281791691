import React, { useState, useRef, useEffect } from "react";
import { QuestionData, TABS } from "./types";
import "brace/mode/pgsql";
import "brace/theme/terminal";
import "react-toastify/dist/ReactToastify.css";
import SolutionView from "./SolutionView";
import SampleOutputTable from "./SampleOutputTable";
import LoginButton from "../components/LoginButton";
import { useUserContext } from "../authentication/userContext";
import Button from "../uiKit/button";
import { useNavigate } from "react-router-dom";
import AiOpinion from "./AiOpinion";

export const QuestionInfo: React.FC<{
  questionData: QuestionData | null;
  isQuestionLocked: boolean;
  query: string;
}> = ({ questionData, isQuestionLocked, query }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(TABS.DESCRIPTION);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [feedback, setFeedback] = useState<string | null>(
    isQuestionLocked ? "Upgrade to premium to enable this feature" : null,
  );
  const popupRef = useRef<HTMLDivElement>(null);

  const handleImageClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        handleClosePopup();
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  useEffect(() => {
    setActiveTab(TABS.DESCRIPTION);
  }, [questionData]);

  return (
    <div className="w-full px-4 bg-slate-100 rounded-md max-h-96 overflow-y-auto">
      <div className="flex border-b mb-4 text-sm">
        {[TABS.DESCRIPTION, TABS.ERD, TABS.HINT, TABS.SOLUTION, TABS.AI].map(
          (tab) => (
            <div
              key={tab}
              className={`px-2 py-2 cursor-pointer ${
                activeTab === tab
                  ? "text-dnPurple border-b-2 border-dnPurple"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ),
        )}
      </div>
      {isQuestionLocked && activeTab !== TABS.ERD && (
        <div className="my-8 flex gap-4 flex-col items-center text-lg font-semibold">
          {`To see the ${activeTab.toLowerCase()} go premium`}
          {user ? (
            <Button onClick={() => navigate("/premium")}>Go Premium</Button>
          ) : (
            <LoginButton />
          )}
        </div>
      )}
      {(!isQuestionLocked || activeTab === TABS.ERD) && (
        <div>
          {activeTab === TABS.DESCRIPTION && questionData && (
            <div>
              <div
                className="text-start text-md p-2 text-gray-800 mb-2"
                dangerouslySetInnerHTML={{
                  __html: questionData.description,
                }}
              ></div>
              {questionData.outputSampleHeaders?.length > 0 && (
                <div className="flex flex-row pb-1 pt-3 gap-2 flex-wrap">
                  <div className="text-lg font-semibold">Sample Output:</div>
                  <div className="pt-1 text-sm font-thin">
                    (The data is an example only)
                  </div>
                </div>
              )}
              <SampleOutputTable
                outputSampleHeaders={questionData.outputSampleHeaders}
                outputSampleValues={questionData.outputSampleValues}
              />
            </div>
          )}
          {activeTab === TABS.ERD && (
            <div className="cursor-pointer" onClick={handleImageClick}>
              <img src={`/erd.png`} alt="ERD Diagram" className="w-full" />
            </div>
          )}
          {activeTab === TABS.HINT && questionData && (
            <div
              className="text-start text-md p-2 text-gray-800 mb-2"
              dangerouslySetInnerHTML={{
                __html: questionData.hint,
              }}
            ></div>
          )}
          {activeTab === TABS.SOLUTION && questionData && (
            <SolutionView solution={questionData.solution} />
          )}
          {activeTab === TABS.AI && questionData && (
            <AiOpinion
              questionId={questionData.id}
              query={query}
              feedback={feedback}
              setFeedback={setFeedback}
            />
          )}
        </div>
      )}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={popupRef} className="relative bg-white p-4 rounded-lg">
            <button
              className="absolute top-2 right-2 text-black"
              onClick={handleClosePopup}
            >
              &times;
            </button>
            <img
              src={`/erd.png`}
              alt="ERD Diagram"
              className="max-w-full max-h-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};
