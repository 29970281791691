import { User } from "../userContext";

export const FREE_QUESTION_IDS = [
  "9d1ed776-3706-4b23-b0c9-ced42ffa0f12",
  "1b6debda-9d16-43c0-bb41-05dd12c343ac",
  "62054e27-1ec3-4210-8dcd-f634c3ceb3ed",
  "d781168f-c913-43f3-b2e0-0c54b6dfc4c3",
  "951234c6-4b67-40ce-87ea-67eadb413ff9",
  "559d647b-1af2-46db-b15d-f2725d3f2320",
  "d1e34a19-fd8e-45ad-a220-c0b7d6d04b24",
  "152e9b74-f850-4b47-880b-8bf2b53e44c3",
];

export const isQuestionEnabled = (questionId: string, user: User | null) => {
  const isDisabled =
    !user?.isPremium && !FREE_QUESTION_IDS.includes(questionId);
  return !isDisabled;
};
