import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../uiKit/button";
import { QuestionData } from "../sqlEditor/types";
import { useQuery } from "@tanstack/react-query";
import { getQuestionsQuery } from "../api/questionsApi";

const SuccessPopup: React.FC<{
  onClose: () => void;
  questionId: string;
}> = ({ onClose, questionId }) => {
  const navigate = useNavigate();

  const [nextQuestionId, setNextQuestionId] = useState<string | null>(null);

  const [questions, setQuestions] = useState<QuestionData[]>([]);

  const { data: questionsResponse = { data: [], success: true } } =
    useQuery(getQuestionsQuery());

  useEffect(() => {
    if (questionsResponse.success) {
      setQuestions(questionsResponse.data);
    }
  }, [questionsResponse]);

  useEffect(() => {
    const sortedQuestions = questions.sort((a, b) => {
      return a.position - b.position;
    });
    const currentQuestionId = sortedQuestions.findIndex(
      (q) => q.id === questionId,
    );
    if (
      currentQuestionId === -1 ||
      currentQuestionId === sortedQuestions.length - 1
    ) {
      setNextQuestionId(null);
      return;
    }
    const nextQuestionIndex = sortedQuestions[currentQuestionId + 1]?.id;

    setNextQuestionId(nextQuestionIndex || null);
  }, [questionId, questions]);

  const handleStayHere = () => {
    onClose();
  };

  const handleBackToQuestions = () => {
    navigate("/questions");
  };

  const handleNextQuestion = () => {
    navigate(`/question/${nextQuestionId}`);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg md:w-1/2 md:h-1/3">
        <div className="text-center text-3xl font-medium mb-2">
          Your answer is correct! 🎉
        </div>
        {nextQuestionId && (
          <div className="text-center text-xl font-medium">
            You can move on to the next question now
          </div>
        )}
        <div className="flex justify-center mt-10 gap-4">
          <Button
            variant="secondary"
            className="my-1"
            onClick={handleBackToQuestions}
          >
            Back to Questions
          </Button>
          <Button variant="secondary" className="my-1" onClick={handleStayHere}>
            Stay Here
          </Button>
          {nextQuestionId && (
            <Button className="my-1" onClick={handleNextQuestion}>
              Next Question
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;
